import client from "./_client";

const http = client("Image Manager");
const endpoint = "/image_manager";

/**
 * Retrieve chapter index for a pratilipi
 *
 * @param {Object} params
 * @param {Number} params.pratilipiId
 * @param {String} params.tag
 * @param {Number} params.cursor
 */
export async function recommend(params) {
    const { data } = await http.get(endpoint + "/recommendation", { params });
    return data;
}

/**
 * Download image and return blob
 *
 * @param {string} imageURI
 */
export async function toBlob(imageURI) {
	return fetch(imageURI)
		.then(res => res.blob())
}
