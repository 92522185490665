export default {
    /**
     * Based on the origin, decide what endpoint to use
     *
     * @returns string
     */
    decideEndpoint() {
        const { origin } = window.location;
        if(origin.includes("site.pratilipicomics.com")) {
            return `https://gamma.pratilipi.com/api`
        } else if(origin.includes("pratilipicomics.com")) {
            return `https://prod.pratilipi.com/api`
        } else return "/api"
    },

    decideComicsEndpoint() {
        const { origin } = window.location;
        if(origin.includes("site.pratilipicomics.com")) {
            return `https://gamma.pratilipicomics.com`
        } else if(origin.includes("pratilipicomics.com")) {
            return `https://prod.pratilipicomics.com`
        } else return "https://gamma.pratilipicomics.com"
    },

    /**
     * Check id we are in development mode
     *
     * @returns boolean
     */
    inDevelopment() {
        return process.env.NODE_ENV === "development";
    }
};
