import client from "./_client";

const http = client("Next Pratilipi Service V2");
const endpoint = "oasis/v1.0/pratilipis";
const nextPratilipiEndPoint = "/nextPratilipi/v2.0";

/**
 * Find a next Pratilipi by it's ID
 *
 * @param {Number} pratilipiId
 */
export async function findNextPratilipiById(pratilipiId) {
    const _endpoint = `${endpoint}${nextPratilipiEndPoint}`;
    const params = { pratilipiId };
    const { data } = await http.get(_endpoint, { params });
    return data;
}
