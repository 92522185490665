import client from "./_client";

const http = client("Pratilipi Service v2");
const endpoint = "pratilipis/v2.0";
const seriesEndPoint = "series/v2.0";

/**
 * Find a pratilipi by it's ID
 *
 * @param {Number} pratilipiId
 */
export async function findById(pratilipiId) {
    const _endpoint = endpoint + "/id/" + pratilipiId;
    const { data } = await http.get(_endpoint);

    return data.data;
}

/**
 * Find pratilipis by it's SeriesID
 *
 * @param {Number} seriesId
 * @param {Number} authorId
 */
export async function findBySeriesId(
    seriesId,
    authorId,
    state = "PUBLISHED",
    limit = 10
) {
    const params = { seriesId, limit };
    const states = state.split(",").map((s) => {
        return http.get(endpoint, { params: { ...params, authorId, state: s } });
    })
    const result = await Promise.all(states)
    const datas = result.map((r) => r.data.data)
    const data = datas.reduce((acc, curr) => {
        return acc.concat(curr)
    })
    console.log(datas)
    return data
}

/**
 * Create a pratilipi with provided partial data
 *
 * @param {Object} partial
 */
export async function create(partial) {
    const { data } = await http.post(endpoint, partial);

    return data.data;
}
export async function createSeries(partial) {
    const { data } = await http.post(seriesEndPoint, partial);

    return data.data;
}
/**
 * Delete a pratilipi by it's ID
 *
 * @param {Number} pratilipiId
 */
export async function destroy(pratilipiId) {
    const _endpoint = `${endpoint}/${pratilipiId}`;
    const { data } = await http.delete(_endpoint);

    return data.data;
}

/**
 * Save pratilipi
 *
 * @param {Number} pratilipiId
 * @param {Object} changes
 */
export async function save(pratilipiId, changes) {
    const _endpoint = `${endpoint}/${pratilipiId}`;
    const { data } = await http.patch(_endpoint, changes);

    return data.data;
}

/**
 * Save a pratilipi's cover image
 *
 * @param {Number} pratilipiId
 * @param {Object} changes
 */
export async function saveCoverImage(pratilipiId, blob) {
    const _endpoint = `${endpoint}/cover`;
    const params = { pratilipiId };

    const fd = new FormData();
    fd.append("pratilipiId", pratilipiId);
    fd.append("data", blob);

    const { data } = await http.post(_endpoint, fd, { params });

    return data;
}

/**
 * Retrives a list of pratilipi, given their ids
 * separated by comma.
 *
 * == TODO ==
 * 1. Accept array input
 *
 * @param {string} pratilipiIds
 */
export async function listByIds(pratilipiIds) {
    const params = { id: pratilipiIds };
    const { data } = await http.get(endpoint, { params });

    return data.data;
}

/**
 * Retrives a list of series, given their ids
 * separated by comma.
 *
 * == TODO ==
 * 1. Accept array input
 *
 * @param {string} seriesIds
 */
export async function seriesListByIds(seriesIds) {
    const params = {id: seriesIds};
    const {data} = await http.get(seriesEndPoint, {params});

    return data.data;
}


/**
 * Retrieve list of system tags; for a given language
 *
 * @param {string} language
 */
export async function listTagsByLanguage(language, type = "system") {
    const _endpoint = "/categories/v2.0";
    const params = { language, type };
    const { data } = await http.get(_endpoint, { params });

    // because response format changed
    return data.data.reduce(
        (acc, item) => ({ ...acc, [item.nameEn]: item }),
        {}
    );
}
