import * as axios from "axios";

const headers = {
  "Ocp-Apim-Subscription-Key": "62326346622148e0b64593e5349e26d1",
  "Ocp-Apim-Subscription-Region": "centralindia",
  "Content-type": "application/json",
}
const instance = axios.create({ baseURL: "https://api.cognitive.microsofttranslator.com/", headers: headers });
// instance.interceptors.request.use((config) => {
//   config.headers["Ocp-Apim-Subscription-Key"] = "62326346622148e0b64593e5349e26d1"
//   config.headers["Ocp-Apim-Subscription-Region"] = "centralindia"
//   config.headers["Content-type"] = "application/json"
// })

const languageMapping = {english: "en", hindi: "hi", marathi: "mr", gujarati: "gu", tamil: "ta", bengali: "bn"}
const languageScriptMapping = {hindi: "Deva", marathi: "Deva", gujarati: "Gujr", tamil: "Taml", bengali: "Beng", english: "Latn"}

export async function transliterate(fromLanguage, toLanguage, text) {
  const params = {
    "api-version": "3.0",
    language: languageMapping[fromLanguage.toLowerCase()],
    fromScript: languageScriptMapping[fromLanguage.toLowerCase()],
    toScript: languageScriptMapping[toLanguage.toLowerCase()]
  }
  const body = [
    {
      "text": text
    }
  ]
  return instance.post("/transliterate", body, { params })
    .then(result => result.data)
    .then(data => data[0].text)
}
