import Utility from "./_utility";
import _ from "lodash";
/**
 * Request Interceptor
 *
 * This adds auth header (AccessToken) to outgoing requests.
 * But, in case one was added while making the request,
 * we do not override it. Expected behaviour, is it
 * not ?
 *
 * @param {*} config
 */
function getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return null;
}

const addAccessTokenToHeaders = config => {
    if (config.headers.hasOwnProperty("access-token") || config.headers.hasOwnProperty("accesstoken")) {
        return config;
    }

    const token = getCookie("access_token");

    if (token) {
        if (config.baseURL.includes("pratilipicomics.com")) {
            config.headers["access-token"] = token;
        } else if (config.baseURL.includes("localhost:3333")) {
            config.headers["user-id"] = 0;
        } else {
            config.headers["accesstoken"] = token;
        }
    }

    return config
};

const sendApiResponseFailEvent = error => {
    const responseErrorObject = {
        url: _.get(error, "config.url"),
        status: _.get(error, "error.response.status"),
        method: _.get(error, "config.method"),
        message: _.get(error, "message")
    };
    // triggerAnanlyticsEvent("API_RESPONSE_FAIL", "CONTROL", responseErrorObject);
};
/**
 * Response Interceptor
 *
 * 1. This checks for guest users and redirects them to the login
 * page. Mostly used to handle invalidated session.
 * 2. Also sends the event on api fail
 *
 * @param {*} error
 */
const apiFail = error => {
    sendApiResponseFailEvent(error);
    redirectToLoginOn401(error);

    return Promise.reject(error);
};
const redirectToLoginOn401 = error => {
    if (_.get(error, "error.response.status") === 401) {
        console.log("401 Unauthorized : Sending the user to /login");
        window.location.href = "/login";
    }
};

/**
 * Response Interceptor
 *
 * This logs api response errors when in development mode.
 *
 * == TODO ==
 * 1. Use debug package to log errors
 * 2. It should log errors to error reporting platform
 * when not in development mode.
 *
 * @param {*} error
 */
const logErrorsInDevelopment = error => {
    if ("response" in error) {
        const url = _.get(error, "config.url");
        const status = _.get(error, "response.status");

        console.error(`[HTTP ERROR ${status}] ${url}`, error);
    }
    return Promise.reject(error);
};

export default {
    /**
     * Attach interceptors to given Axios instance
     *
     * @param {AxiosInstance} instance
     */
    attach(instance) {
        // apply request interceptors
        instance.interceptors.request.use(addAccessTokenToHeaders);

        // apply response interceptors
        instance.interceptors.response.use(null, apiFail);

        if (Utility.inDevelopment()) {
            console.log("[DEV] Adding error logger to axios interceptors");
            instance.interceptors.response.use(null, logErrorsInDevelopment);
        }
    }
};
