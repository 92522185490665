<template>
    <div class="card" @click="$emit('click', comic)">
        <img
                class="card-img-top"
                style="height:350px"
                :src="comic.attributes.coverImageUrl"
                :alt="comic.attributes.title"
        />

        <div class="card-body">
            <h5 class="card-title" v-text="comic.attributes.displayTitle" />
            <small class="card-text" v-text="comic.attributes.titleEn || '...'" />
        </div>

        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <strong v-text="comic.attributes.seriesPart.published.length" />
                published &
                <strong v-text="comic.attributes.seriesPart.drafted.length" />
                drafted parts
            </li>
            <li class="list-group-item">
                <strong>Updated</strong>
                {{ comic.attributes.updatedAt | listingDateSchemaOrgFormat }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "ComicCard",
        props: {
            comic: {
                type: Object,
                required: true
            }
        }
    };
</script>
