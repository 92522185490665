import client from "./_client";

const http = client("Pratilipi Service v1");
const endpoint = "pratilipi";
const seriesEndpoint = "series";

/**
 * Save a pratilipi's cover image
 *
 * @param {Number} pratilipiId
 * @param {Object} changes
 */
export async function saveCoverImage(pratilipiId, blob) {
    const _endpoint = `${endpoint}/cover`;
    const params = { pratilipiId };

    const fd = new FormData();
    fd.append("pratilipiId", pratilipiId);
    fd.append("data", blob);

    const { data } = await http.post(_endpoint, fd, { params });

    return data;
}
export async function saveSeriesCoverImage(seriesId, blob) {
    const _endpoint = `${seriesEndpoint}/cover`;
    const params = { seriesId };

    const fd = new FormData();
    fd.append("seriesId", seriesId);
    fd.append("data", blob);

    const { data } = await http.post(_endpoint, fd, { params });

    return data;
}
