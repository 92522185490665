import client, {comicApiGateway} from "./_client";
import * as qs from "querystring";
import axios from "axios";

const http = client("Content Service");
const endpoint = "/pratilipi/content";

export async function getContent(pratilipiId, _apiVer = "4") {
    const params = { pratilipiId, _apiVer };
    return http.get(endpoint, { params });
}

export async function getMetaContent(pratilipiId) {
    const comicHttp = comicApiGateway();
    // const comicHttp = axios.create({ baseURL: "http://127.0.0.1:8085/" });
    const params = { pratilipiId };
    const _endpoint = `comic-meta/v1.0/pratilipi/content`;

    const { data } = await comicHttp.get(_endpoint, { params });
    return data;
}

/**
 * Retrieve chapter index for a pratilipi
 *
 * @param {number} pratilipiId
 */
export async function listIndex(pratilipiId) {
    const params = { pratilipiId };
    return http.get(endpoint + "/index", { params });
}

/**
 * Add chapter
 *
 * @param {object} data
 */
export async function addChapter(pratilipiId, chapterNo = 1) {
    const body = { pratilipiId };
    const { data } = await http.post(endpoint + "/chapter/add", body);

    return data;
}

/**
 * Save chapter
 *
 * @param {object} data
 */
export async function saveChapter(data) {
    data._apiVer = 4;
    return http.post(endpoint, data);
}

/**
 * Save chapter
 *
 * @param {object} data
 */
export async function saveChapterComics(data) {
    // data._apiVer = 4;
    return http.post(endpoint, data);
}

export async function uploadImage(pratilipiId, file, pageNo = 1) {
    const _endpoint = `${endpoint}/image`;
    const params = { pratilipiId, pageNo };
    const body = new FormData();
    body.append("file", file);
    body.append("pratilipiId", pratilipiId);
    body.append("pageNo", 1);

    return http.post(_endpoint, body, { params });
}

export async function uploadVideo(seriesId, pratilipiId, file, playTime, orientation) {
    const comicHttp = comicApiGateway();
    // const comicHttp = axios.create({ baseURL: "http://127.0.0.1:8085/" });
    const _endpoint = "comic-content/v1.0/upload/motion-comic"
    const params = { seriesId, pratilipiId, playTime, orientation };
    const body = new FormData();
    body.append("file", file);

    const { data } = await comicHttp.post(_endpoint, body, { params, headers: {
        "Content-Type": "multipart/form-data"
    } });
    return data;
}
