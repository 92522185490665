<template>
    <div>
        <ul class="pagination" v-if="total && total>0" style="cursor:default">
            <li class="page-item" :class="{ disabled : !this.hasPrev }" @click="onPrevClick">
                <btn class="page-link">←</btn>
            </li>
            <!-- <li class="page-item">
              <span class="page-link text-muted">{{ description }}</span>
            </li>-->
            <li class="page-item" :class="{ disabled : !this.hasNext }" @click="onNextClick">
                <btn class="page-link">→</btn>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
            total: {
                type: Number,
                required: true,
                default: 0
            },
            pageSize: {
                type: Number,
                required: true,
                default: 3
            },
            next: {
                type: String,
                required: true,
                default: ""
            },
            prev: {
                type: String,
                required: true,
                default: ""
            }
        },
        data() {
            return {};
        },
        computed: {
            totalPages() {
                return Math.round(this.total / this.pageSize);
            },
            hasNext() {
                return this.next.length > 0;
            },
            hasPrev() {
                return this.prev.length > 0;
            },
            nextParsed() {
                const parsed = this.parse(this.next);
                parsed.limit = this.pageSize;
                return parsed;
            },
            prevParsed() {
                const parsed = this.parse(this.prev);
                parsed.limit = this.pageSize;
                return parsed;
            },
            description() {
                const p = this.prevParsed;
                const n = this.nextParsed;
                console.log(
                    p,
                    n,
                    (parseInt(n.offset) || 0) + this.pageSize,
                    this.pageSize
                );
                const currentPage = parseInt(n.offset || 0) / this.pageSize;
                return `${currentPage}/${this.totalPages}`;
                // return `${this.total} total`;
                if (!this.hasPrev && this.hasNext) {
                    const n = this.nextParsed;
                    return `1/${this.total / this.pageSize}`;
                } else if (this.hasPrev && this.hasNext) {
                    const p = this.prevParsed;
                    const n = this.nextParsed;
                    const currentPage = n.offset / this.pageSize;
                    return `${currentPage}/${this.totalPages}`;
                }
                return `1/1`;
            }
        },
        methods: {
            onNextClick() {
                this.hasNext && this.$emit("click:next", this.nextParsed);
            },
            onPrevClick() {
                this.hasPrev && this.$emit("click:prev", this.prevParsed);
            },
            parse(query) {
                const result = {};
                query
                    .split("&")
                    .filter(part => part.length)
                    .forEach(function(part) {
                        const item = part.split("=");
                        result[item[0]] = decodeURIComponent(item[1]);
                    });
                return result;
            }
        }
    };
</script>
