import { formatParams, getCookie, httpUtil, setCookie } from "./HttpUtil";

// import Raven from 'raven-js';

const { origin } = window.location;
let API_PREFIX = "";
if (origin.includes("test.pratilipicomics.com")) {
  API_PREFIX = `https://gamma.pratilipi.com/api`;
} else if (origin.includes("pratilipicomics.com")) {
  API_PREFIX = `https://prod.pratilipi.com/api`;
} else API_PREFIX = "/api";

const ACCESS_TOKEN_API = "/user/accesstoken";

/* Search */
const SEARCH_PREFIX = "/search/v2.0";
const SEARCH_TRENDING_API = "/trending_search";
const SEARCH_CORE_API = "/search";

/* Search with Khoj Service */
const KHOJ_V1_PREFIX = API_PREFIX + "/khoj/v1.0";
const KHOJ_V1_SEARCHBYQUERY = KHOJ_V1_PREFIX + "/search";
const KHOJ_V1_TRENDING = KHOJ_V1_PREFIX + "/trending";

/* Recommendation Api */
const RECOMMENDATION_PREFIX = "/recommendations/v2.2";
const RECOMMENDATION_PRATILIPI_API = "/pratilipis";

const PAGE_CONTENT_API = "/page/content";
const HELP_SECTIONS_API = "/init/v4.0/static/index";
const PRATILIPI_API = "/pratilipi?_apiVer=2";
const PRATILIPI_LIST_API = "/pratilipi/list?_apiVer=3";
const PRATILIPI_LIST_API_V4 = "/init/v4.0/list";
const PRATILIPI_LIST_RECENT_API = "/stats/recent_published";
const PRATILIPI_LIST_RECENT_API_V2 = "/stats/v2.0/recent_published";
const PRATILIPI_LIST_HIGH_RATED_API = "/stats/high_rated";
const PRATILIPI_LIST_HIGH_RATED_API_V2 = "/stats/v2.0/high_rated";
const PRATILIPI_SERIES_LIST_API = "/list/v1.1";
const PRATILIPI_SERIES_LIST_API_V2 = "/list/v1.2";
const PRATILIPI_CONTENT_API = "/pratilipi/content";
const PRATILIPI_CLIPPED_CONTENT_API = "/content/v1.0/contents/clipped";
const PRATILIPI_CONTENT_INDEX_API = "/pratilipi/content/index";
const PRATILIPI_TAGS_UPDATE_API = "/pratilipi/tags/update";
const PRATILIPI_NEW_API = "/pratilipis";
const USER_PRATILIPI_API = "/userpratilipi";

// LIBRARY APIS
// GET LIST APIS
const LIBRARY_LIST_V_1_1_API = "/oasis/v1.0/library/v1.1";
// GET POST DELETE APIS
const LIBRARY_API = "/oasis/v1.0/library";

const AUTHOR_PROFILE_API = "/oasis/v1.0/authors/profile/v2.0/";
const AUTHOR_API = "/author";
const AUTHOR_NEW_API = "/authors/v1.0";
const AUTHOR_DASHBOARD_API = "/stats/author_dashboard";
const AUTHOR_COUNT_API_V1 = "/count/v1.0";
const READER_DASHBOARD_API = "/stats/v1.0/reader_dashboard";
const USER_API = "/user?_apiVer=2";
const USER_LOGIN_API = "/user/login";
const USER_IS_VALID = "/users/v2.0/identifiers/is-valid";
const USER_LOGIN_FACEBOOK_API = "/user/login/facebook";
const USER_LOGIN_GOOGLE_API = "/user/login/google";
const USER_REGISTER_API = "/user/register";
const USER_PASSWORD_UPDATE_API = "/user/passwordupdate";
const USER_LOGOUT_API = "/user/logout";
const NOTIFICATION_API = "/notifications/v1.0";
const NOTIFICATION_LIST_API = "/notifications/v1.0";
const NAVIGATION_LIST_API = "/navigation/list";
const USER_PRATILIPI_REVIEW_LIST_API = "/social/v2.1/pratilipis";
const USER_PRATILIPI_REVIEW_API_V2 = "/social/v2.0/pratilipis";
const USER_PRATILIPI_REVIEW_API_V4 = "/oasis/v1.0/social/reviews/v4.0";
const COMMENT_LIST_API = "/comment/list";
const REVIEW_COMMENT_API = "/social/v2.0/reviews";
const USER_AUTHOR_FOLLOW_POST_API = "/follows/v2.0/authors";
const VOTE_API = "/vote";
const INIT_API = "/home/v2.2";
const INIT_BANNER_LIST_API = "/init/banner/list";
const USER_AUTHOR_FOLLOWERS_LIST_API = "/follows/v2.0/authors";
const USER_AUTHOR_FOLLOWING_LIST_API = "/follows/v2.0/users";
const EVENT_API = "/event";
const EVENT_ENTRY_API = "/event/entry";
const CONTENT_API = "/pratilipi/content";
const INDEX_API = "/index";
const CHAPTER_ADD = "/chapter/add";
const CHAPTER_DELETE = "/chapter/delete";
const EVENT_LIST_API = "/event/list";
const CONTACT_API = "/contact";
const TAGS_API = "/pratilipi/v2/categories/system";
const USER_EMAIL_API = "/user/email";
const TOP_AUTHORS_API = "/author/list/readcount";
const WEB_DEVICES_API = "/web-push/fcmToken";
const MARKETING_API_UNSUBSCRIBE = "/marketing/v1.0/newsletter/unsubscribe";
const REFERRAL_API_UNSUBSCRIBE = "/social-connect/unsubscribe";
const MARKETING_API_PREFERENCE = "/marketing/v1.0/newsletter/preference";

const EVENT_PARTICIPATE_PREFIX = "/event-participate";
const EVENT_PARTICIPATE_PREFIX_ADMIN = "/event-participate/admin";
const EVENT_PARTICIPATE_GET = "/metadata";
const EVENT_PARTICIPATE_LIST = "/list";
const EVENT_PARTICIPATE_CREATE = "/metadata";
const EVENT_PARTICIPATE_UPDATE = "/metadata";
const EVENT_PARTICIPATE_CONTENT = "/content";
const EVENT_PARTICIPATE_PUBLISH = "/publish";

const INIT_VIDEOSERIES_LIST_API = "/init/v2.0/videoseries";
const INIT_VIDEOSERIES_PLAYLIST = "/init/v2.0/videos";
const INIT_VIDEOSERIES_DETAILS = "/init/v2.0/videoseries/";
const INIT_OTHER_VIDEOS = "/init/v2.0/videos/other";
const CATEGORIES_WITHOUT_FILER_API = "/init/v1.0/categories-without-filter";

const BLOGS_API = "/oasis/blogs/v1.0";
const BLOGS_LIST_API = "/oasis/blogs/v1.0/list";
const AUTHOR_INTERVIEWS_API = "/oasis/author-interviews/v1.0";
const AUTHOR_INTERVIEWS_LIST_API = "/oasis/author-interviews/v1.0/list";
const READ_PERCENTAGE_API = "/user_pratilipi/v2.0/user_pratilipis";

const READER_BATCH_API = "/web/v1.0/reader/readerBatch";
const READER_BATCH_IMAGE_API = "/web/v1.0/imageReader/readerBatch";
const READER_CHAPTER_API = "/web/v1.0/reader/readerChapter";

const INIT_API_VAPSI = "/init/v2.0/vapsi";

const EVENT_ENTRY_SUBMIT = "/submit";
const EVENT_ENTRY_CANCEL_PARTICIPATION = "/donotparticipate";

const AUTHOR_LEADERBOARD_API = "/stats/v1.0/author_leaderboard";

const USER_PREFERENCE_API = "/users/v2.0/preferences";

const WRITER_CORNER_API = "/authors/v1.0/writer-corner";
const SERIES_API = "/series/v1.0";
const SERIES_CHAPTERS_API = "/series/v1.0/pratilipi";

//comic and series creation api
const SERIES_LIST_API_AUTHOR = "/oasis/v1.0/authors/profile/v2.0";
const PRATILIPI_ADD_TO_SERIES = "/series/v2.0";
const PRATILIPI_IMAGE_UPLOAD = "/pratilipi/content/image";
const SERIES_COVER_IMAGE_UPLOAD = "/series/cover";

const LIST_SUGGESTIONS_API = "/init/v0.1/list-suggestions";
const TOP_AUTHORS_API_V1 = "/stats/v1.0/top_authors";

const WORD_MEANING = "/gruite/meaning";
const WORD_SYNONYMS = "/gruite/synonyms";

// P2P
const COGNITO_AUTH_API = "/users/v2.0/cognito-auth";
const APPSYNC_P2P_API =
  "https://bbgf3fyrvrdoneq5onckfmhof4.appsync-api.ap-south-1.amazonaws.com/graphql";
const AUTHOR_CONCISE_METADATA_API = "/oasis/v1.0/authors/conciseMetadata";

const Request = function(name, api, params) {
  return {
    name: name,
    api: api,
    params: params != null ? encodeURIComponent(formatParams(params)) : null,
  };
};

const processRequests = function(requests) {
  const params = {};
  for (let i = 0; i < requests.length; i++) {
    const request = requests[i];
    params[request.name] = request.api;
    if (request.params != null) {
      params[request.name] +=
        encodeURIComponent(request.api.indexOf("?") > -1 ? "&" : "?") +
        request.params;
    }
  }
  return JSON.stringify(params);
};

const processGetResponse = function(response, status, aCallBack) {
  if (aCallBack != null) {
    aCallBack(
      status === 200
        ? {
            status,
            response,
          }
        : {
            status,
            response,
          }
    );
  }
};

const processPostResponse = function(
  response,
  status,
  successCallBack,
  errorCallBack
) {
  if (status === 200 && successCallBack != null) {
    successCallBack(response);
  } else if (status !== 200 && errorCallBack != null) {
    errorCallBack(response);
  }
};

const addToLibrary = (
  referenceType,
  referenceId,
  successCallBack,
  errorCallBack
) => {
  if (referenceId == null || referenceId === "") return;
  httpUtil.post(
    API_PREFIX + LIBRARY_API + "/" + referenceType + "/" + referenceId,
    null,
    null,
    function(response, status) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    }
  );
};

// DELETE
const deleteFromLibrary = (
  referenceType,
  referenceId,
  successCallBack,
  errorCallBack
) => {
  if (referenceId == null || referenceId === "") return;
  httpUtil.delete(
    API_PREFIX + LIBRARY_API + "/" + referenceType + "/" + referenceId,
    null,
    null,
    function(response, status) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    }
  );
};

/* DataAccessor */
import * as apiPratilipiV1 from "./api/pratilipi";
import * as apiPratilipiV2 from "./api/pratilipi.v2";
import * as apiContentV1 from "./api/content";
import * as apiNextPratilipiV2 from "./api/nextPratilipi.v2";
import * as apiImageManagerV1 from "./api/image-manager";
import * as apiSeriesV2 from "./api/series_v2";
import * as apiComic from "./api/comic";
import * as apiTransliterate from "./api/transliterate";

export {
  apiPratilipiV1,
  apiPratilipiV2,
  apiContentV1,
  apiImageManagerV1,
  apiSeriesV2,
  apiNextPratilipiV2,
  apiComic,
  apiTransliterate,
};

export default {
  getPratilipiBySlug: (
    slug,
    includeUserPratilipi,
    includeClippedContent,
    includeNextPratilipi,
    aCallBack
  ) => {
    const requests = [];
    const newPratilipiApiParams = {
      slug: slug,
      nextPratilipi: true,
    };
    if (includeNextPratilipi) {
      newPratilipiApiParams["nextPratilipi"] = false;
    }
    requests.push(
      new Request("req1", PRATILIPI_NEW_API, newPratilipiApiParams)
    );

    if (includeClippedContent) {
      requests.push(
        new Request("req3", PRATILIPI_CLIPPED_CONTENT_API, {
          id: "$req1.pratilipiId",
          charLength: 200,
        })
      );
    }

    if (includeUserPratilipi) {
      requests.push(
        new Request("req2", USER_PRATILIPI_API, {
          pratilipiId: "$req1.pratilipiId",
        })
      );
    }
    let userReview = null;
    httpUtil.get(
      API_PREFIX,
      null,
      { requests: processRequests(requests) },
      function(response, status) {
        if (aCallBack != null) {
          const pratilipi =
            response.req1 && response.req1.status === 200
              ? response.req1.response
              : null;
          const userpratilipi =
            includeUserPratilipi &&
            response.req2 &&
            response.req2.status === 200
              ? response.req2.response
              : null;
          const clippedContent =
            includeClippedContent &&
            response.req3 &&
            response.req3.status === 200
              ? response.req3.response
              : null;
          const params = {
            sort: "most-helpful",
            offset: "0",
            limit: "1",
          };
          let nextPratilipi = {};
          if (includeNextPratilipi) {
            nextPratilipi = apiNextPratilipiV2.findNextPratilipiById(
              pratilipi.pratilipiId
            );
          }

          if (includeUserPratilipi) {
            httpUtil.get(
              API_PREFIX +
                `${USER_PRATILIPI_REVIEW_API_V4}/${pratilipi.pratilipiId}`,
              null,
              params,
              function(response, status) {
                if (
                  status === 200 &&
                  response &&
                  response.data &&
                  response.data.userReview &&
                  response.data.userReview.attributes
                )
                  userReview = response.data.userReview.attributes;
                else userReview = null;
                aCallBack(
                  pratilipi,
                  userpratilipi,
                  clippedContent,
                  userReview,
                  nextPratilipi
                );
              }
            );
          } else {
            aCallBack(
              pratilipi,
              userpratilipi,
              clippedContent,
              null,
              nextPratilipi
            );
          }
        }
      }
    );
  },
  getPratilipiBySlugWithoutNextPratilipi: (
    slug,
    includeUserPratilipi,
    includeClippedContent,
    aCallBack
  ) => {
    const requests = [];
    requests.push(new Request("req1", PRATILIPI_NEW_API, { slug: slug }));

    if (includeClippedContent) {
      requests.push(
        new Request("req3", PRATILIPI_CLIPPED_CONTENT_API, {
          id: "$req1.pratilipiId",
          charLength: 200,
        })
      );
    }

    if (includeUserPratilipi) {
      requests.push(
        new Request("req2", USER_PRATILIPI_API, {
          pratilipiId: "$req1.pratilipiId",
        })
      );
    }
    let userReview = null;

    httpUtil.get(
      API_PREFIX,
      null,
      { requests: processRequests(requests) },
      function(response, status) {
        if (aCallBack != null) {
          const pratilipi =
            response.req1 && response.req1.status === 200
              ? response.req1.response
              : null;
          const userpratilipi =
            includeUserPratilipi &&
            response.req2 &&
            response.req2.status === 200
              ? response.req2.response
              : null;
          const clippedContent =
            includeClippedContent &&
            response.req3 &&
            response.req3.status === 200
              ? response.req3.response
              : null;
          if (includeUserPratilipi) {
            httpUtil.get(
              API_PREFIX +
                `${USER_PRATILIPI_REVIEW_API_V2}/${pratilipi.pratilipiId}/reviews/user-review`,
              null,
              null,
              function(response, status) {
                userReview = status === 200 ? response : null;
                aCallBack(pratilipi, userpratilipi, clippedContent, userReview);
              }
            );
          } else {
            aCallBack(pratilipi, userpratilipi, clippedContent, null);
          }
        }
      }
    );
  },
  getPratilipiById: (pratilipiId, includeUserPratilipi, aCallBack) => {
    const requests = [];
    requests.push(
      new Request("req1", PRATILIPI_API, { pratilipiId: pratilipiId })
    );

    if (includeUserPratilipi) {
      requests.push(
        new Request("req2", USER_PRATILIPI_API, {
          pratilipiId: pratilipiId,
        })
      );
    }

    httpUtil.get(
      API_PREFIX,
      null,
      { requests: processRequests(requests) },
      function(response, status) {
        if (aCallBack != null) {
          const pratilipi =
            response.req1 && response.req1.status === 200
              ? response.req1.response
              : null;
          const userpratilipi =
            includeUserPratilipi &&
            response.req2 &&
            response.req2.status === 200
              ? response.req2.response
              : null;
          aCallBack(pratilipi, userpratilipi);
        }
      }
    );
  },

  getPratilipiAndIndex: (pratilipiId, includeUserPratilipi, aCallBack) => {
    if (pratilipiId === null) return;

    const requests = [];
    requests.push(
      new Request("req1", PRATILIPI_API, {
        pratilipiId: pratilipiId,
      })
    );
    requests.push(
      new Request("req2", PRATILIPI_CONTENT_INDEX_API, {
        pratilipiId: pratilipiId,
      })
    );
    if (includeUserPratilipi) {
      requests.push(
        new Request("req3", USER_PRATILIPI_API, {
          pratilipiId: pratilipiId,
        })
      );
    }

    httpUtil.get(
      API_PREFIX,
      null,
      { requests: processRequests(requests) },
      async function(response, status) {
        if (aCallBack != null) {
          const pratilipi =
            response.req1 && response.req1.status === 200
              ? response.req1.response
              : null;
          const index =
            response.req2 && response.req2.status === 200
              ? response.req2.response
              : null;
          const userpratilipi =
            response.req3 && response.req3.status === 200
              ? response.req3.response
              : null;
          let nextPratilipi = {};
          nextPratilipi = apiNextPratilipiV2.findNextPratilipiById(
            pratilipi.pratilipiId
          );
          aCallBack(pratilipi, index, userpratilipi, nextPratilipi);
        }
      }
    );
  },

  getAuthorBySlugBatch: (pageUri, aCallBack) => {
    const requests = [];
    requests.push(new Request("req1", AUTHOR_NEW_API, { slug: pageUri }));

    httpUtil.get(
      API_PREFIX,
      null,
      { requests: processRequests(requests) },
      function(response, status) {
        if (aCallBack != null) {
          const author =
            response.req1 && response.req1.status === 200
              ? response.req1.response
              : null;
          aCallBack(author, author);
        }
      }
    );
  },

  getAuthorBySlug: (pageUri, aCallBack) => {
    httpUtil.get(API_PREFIX + AUTHOR_NEW_API, null, { slug: pageUri }, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getAuthorById: (authorId, aCallBack) => {
    const requests = [];
    requests.push(new Request("req1", AUTHOR_API, { authorId: authorId }));

    httpUtil.get(
      API_PREFIX,
      null,
      { requests: processRequests(requests) },
      function(response, status) {
        if (aCallBack != null) {
          const author =
            response.req1 && response.req1.status === 200
              ? response.req1.response
              : null;
          aCallBack(author);
        }
      }
    );
  },

  getUserReviewByPratilipiId: (pratilipiId, aCallback) => {
    const params = { sort: "most-helpful", offset: "0", limit: "1" };
    let userReview = null;
    httpUtil.get(
      API_PREFIX + `${USER_PRATILIPI_REVIEW_API_V4}/${pratilipiId}`,
      null,
      params,
      function(response, status) {
        if (
          status === 200 &&
          response &&
          response.data &&
          response.data.userReview &&
          response.data.userReview.attributes
        )
          userReview = response.data.userReview.attributes;
        else userReview = null;
        processGetResponse(userReview, status, aCallback);
      }
    );
  },

  getUserPratilipiByPratilipiId: (pratilipiId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + USER_PRATILIPI_API,
      null,
      { pratilipiId },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  // Reader V2

  getReaderBatch: (chapterSlug, pratilipiSlug, aCallBack) => {
    const queryParams = {};
    if (pratilipiSlug) {
      queryParams.pratilipiSlug = pratilipiSlug;
    }
    if (chapterSlug) {
      queryParams.chapterSlug = chapterSlug;
    }
    return httpUtil.get(
      API_PREFIX + READER_BATCH_API,
      null,
      queryParams,
      function(response, status) {
        // TODO: Remove asap
        if (status === 200) {
          for (let i = 0; i < response.contentIndex.length; i++) {
            let { pageUrl } = response.contentIndex[i];
            pageUrl = pageUrl.split(" ").join("-");
            if (!pageUrl.startsWith("/read/")) {
              pageUrl = `/read/${pageUrl}`;
            }
            response.contentIndex[i].pageUrl = pageUrl;
            response.contentIndex[i].slugId = pageUrl
              .split("/")
              .pop()
              .split("-")
              .pop();
            response.contentIndex[i].chapterNo = i + 1;
            delete response.contentIndex[i].slug;
          }
          if (response.chapterData.pageUrl) {
            response.chapterData.slugId = response.chapterData.pageUrl
              .split("/")
              .pop()
              .split("-")
              .pop();
            delete response.chapterData.pageUrl;
            delete response.chapterData.pratilipiId;
          }
          if (!response.chapterData.slugId) {
            response.chapterData.slugId = chapterSlug;
          }
        }
        // Local Testing for IMAGE contents
        if (status === 200) {
          if (
            (window.location.origin.indexOf(".pratilipi.com") === -1 &&
              window.location.origin.indexOf(".ptlp.co")) === -1
          ) {
            response.chapterData.content = response.chapterData.content.replace(
              /\/api\/pratilipi\/content/g,
              "https://hindi.pratilipi.com/api/pratilipi/content"
            );
          }
        }
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  // Reader V3

  getReaderBatchImage: (chapterSlug, aCallBack) => {
    httpUtil.get(
      API_PREFIX + READER_BATCH_IMAGE_API,
      null,
      { chapterSlug },
      function(response, status) {
        // TODO: Remove asap
        if (status === 200) {
          for (let i = 0; i < response.contentIndex.length; i++) {
            let { pageUrl } = response.contentIndex[i];
            pageUrl = pageUrl.split(" ").join("-");
            if (!pageUrl.startsWith("/read/")) {
              pageUrl = `/read/${pageUrl}`;
            }
            response.contentIndex[i].pageUrl = pageUrl;
            response.contentIndex[i].slugId = pageUrl
              .split("/")
              .pop()
              .split("-")
              .pop();
            response.contentIndex[i].chapterNo = i + 1;
            delete response.contentIndex[i].slug;
          }
          if (response.chapterData.pageUrl) {
            response.chapterData.slugId = response.chapterData.pageUrl
              .split("/")
              .pop()
              .split("-")
              .pop();
            delete response.chapterData.pageUrl;
            delete response.chapterData.pratilipiId;
          }
          if (!response.chapterData.slugId) {
            response.chapterData.slugId = chapterSlug;
          }
        }

        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getReaderChapter: (chapterSlug, pratilipiSlug, aCallBack) => {
    const queryParams = {};
    if (pratilipiSlug) {
      queryParams.pratilipiSlug = pratilipiSlug;
    }
    if (chapterSlug) {
      queryParams.chapterSlug = chapterSlug;
    }
    return httpUtil.get(
      API_PREFIX + READER_CHAPTER_API,
      null,
      queryParams,
      function(response, status) {
        // TODO: Remove asap
        if (status === 200) {
          if (response.pageUrl) {
            response.slugId = response.pageUrl
              .split("/")
              .pop()
              .split("-")
              .pop();
            delete response.pageUrl;
            delete response.pratilipiId;
          }
          if (!response.slugId) {
            response.slugId = chapterSlug;
          }
        }
        // Local Testing for IMAGE contents
        if (status === 200) {
          if (
            (window.location.origin.indexOf(".pratilipi.com") === -1 &&
              window.location.origin.indexOf(".ptlp.co")) === -1
          ) {
            response.content = response.content.replace(
              /\/api\/pratilipi\/content/g,
              "https://hindi.pratilipi.com/api/pratilipi/content"
            );
          }
        }
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getEventBySlug: (slug, limit, offset, aCallBack) => {
    httpUtil.get(
      API_PREFIX + EVENT_API,
      null,
      { slug: slug, limit: limit, offset: offset },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getEventById: (eventId, aCallBack) => {
    if (eventId === null) return;
    httpUtil.get(API_PREFIX + EVENT_API, null, { eventId: eventId }, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getEventList: (language, limit, offset, aCallBack) => {
    httpUtil.get(
      API_PREFIX + EVENT_LIST_API,
      null,
      { language: language, limit: limit, offset: offset },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getVideoseriesList: (language, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_VIDEOSERIES_LIST_API,
      null,
      { language: language },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getOtherVideos: (videoSlug, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_OTHER_VIDEOS,
      null,
      { slug: videoSlug },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getVideoPlayList: (videoseriesSlug, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_VIDEOSERIES_PLAYLIST,
      null,
      { slug: videoseriesSlug },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getVideoDetails: (videoseriesSlug, aCallBack) => {
    const params = {};
    httpUtil.get(
      API_PREFIX + INIT_VIDEOSERIES_DETAILS + videoseriesSlug,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },
  getCurrentVideoPlay: (videosSlug, aCallBack) => {
    const params = {};
    httpUtil.get(
      API_PREFIX + INIT_VIDEOSERIES_PLAYLIST + "/" + videosSlug,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },
  getLatestVideo: (videoseriesSlugLatest, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_VIDEOSERIES_PLAYLIST,
      null,
      { slug: videoseriesSlugLatest },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getBlogPostByUri: (slug, aCallBack) => {
    const params = { slug: slug };
    httpUtil.get(API_PREFIX + BLOGS_API, null, params, function(
      response,
      status
    ) {
      const blogpost = status === 200 ? response : null;
      aCallBack(blogpost);
    });
  },

  getBlogPostListByUri: (language, state, cursor, resultCount, aCallBack) => {
    const params = {
      language: language,
    };
    params["state"] = state != null ? state : "PUBLISHED";
    if (cursor != null) params["cursor"] = cursor;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(API_PREFIX + BLOGS_LIST_API, null, params, function(
      response,
      status
    ) {
      const blogpost = status === 200 ? response : null;
      aCallBack(blogpost);
    });
  },

  getAuthorInterviewByUri: (slug, aCallBack) => {
    const params = {
      slug: slug,
    };
    httpUtil.get(API_PREFIX + AUTHOR_INTERVIEWS_API, null, params, function(
      response,
      status
    ) {
      const blogpost = status === 200 ? response : null;
      aCallBack(blogpost);
    });
  },

  getAuthorInterviewListByUri: (
    language,
    state,
    cursor,
    resultCount,
    aCallBack
  ) => {
    const params = {
      language: language,
    };
    params["state"] = state != null ? state : "PUBLISHED";
    if (cursor != null) params["cursor"] = cursor;
    if (resultCount != null) params["resultCount"] = resultCount;

    httpUtil.get(
      API_PREFIX + AUTHOR_INTERVIEWS_LIST_API,
      null,
      params,
      function(response, status) {
        const blogpost = status === 200 ? response : null;
        aCallBack(blogpost);
      }
    );
  },

  getUser: (aCallBack) => {
    httpUtil.get(API_PREFIX + USER_API, null, null, function(response, status) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getUserById: (userId, aCallBack) => {
    if (userId === null) return;
    httpUtil.get(API_PREFIX + USER_API, null, { userId: userId }, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getIfUserValid: (emailId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + USER_IS_VALID,
      null,
      { type: "EMAIL", value: emailId },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getNotificationList: (cursor, resultCount, language, aCallBack) => {
    const params = { language: language };
    if (cursor != null) params["cursor"] = cursor;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(API_PREFIX + NOTIFICATION_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getNavigationList: (language, aCallBack) => {
    httpUtil.get(API_PREFIX + NAVIGATION_LIST_API, null, { language }, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getReviewList: (
    pratilipiId,
    cursor,
    offset,
    resultCount,
    sort,
    aCallBack
  ) => {
    if (pratilipiId === null) return;
    const URL =
      API_PREFIX + USER_PRATILIPI_REVIEW_LIST_API + `/${pratilipiId}/reviews`;
    const params = {};
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (sort != null) params["sort"] = sort;
    if (params["sort"] == null) params["sort"] = "most-helpful";
    httpUtil.get(URL, null, params, function(response, status) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getReviewCommentList: (userPratilipiId, cursor, resultCount, aCallBack) => {
    if (userPratilipiId === null) return;
    const params = { parentType: "REVIEW", parentId: userPratilipiId };
    if (cursor != null) params["cursor"] = cursor;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(API_PREFIX + COMMENT_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getReviewComments: (reviewId, cursor, resultCount, aCallBack) => {
    if (reviewId === null) return;
    const params = {};
    params["cursor"] = cursor;
    if (resultCount != null) params["resultCount"] = resultCount;
    const URL = API_PREFIX + REVIEW_COMMENT_API + `/${reviewId}/comments`;
    httpUtil.get(URL, null, params, function(response, status) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getPratilipiListByListName: (
    listName,
    cursor,
    offset,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    if (listName === null) return;
    const params = {
      listName: listName,
      state: "PUBLISHED",
      language: language,
    };
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (timeFilter.fromSec != null) params["fromSec"] = timeFilter.fromSec;
    if (timeFilter.toSec != null) params["toSec"] = timeFilter.toSec;
    httpUtil.get(API_PREFIX + PRATILIPI_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getPratilipiListByListNameWithSeries: (
    listName,
    cursor,
    offset,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    const params = {
      listName: listName,
      language: language,
      authorResultCount: 0,
      clippedContentLength: 500,
    };
    if (cursor != null) params["pratilipiCursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["pratilipiResultCount"] = resultCount;
    httpUtil.get(API_PREFIX + PRATILIPI_SERIES_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getPratilipiListByTagsNameWithSeries: (
    categoryName,
    cursor,
    offset,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    const params = {
      categoryName: categoryName,
      state: "PUBLISHED",
      language: language,
      authorResultCount: 0,
      includeSummary: true,
    };
    if (cursor != null) params["pratilipiCursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["pratilipiResultCount"] = resultCount;
    httpUtil.get(
      API_PREFIX + PRATILIPI_SERIES_LIST_API_V2,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPratilipiListByListNameV4: (
    listName,
    cursor,
    offset,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    if (listName === null) return;
    const params = {
      listName: listName,
      state: "PUBLISHED",
      language: language,
    };
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (timeFilter.fromSec != null) params["fromSec"] = timeFilter.fromSec;
    if (timeFilter.toSec != null) params["toSec"] = timeFilter.toSec;
    httpUtil.get(API_PREFIX + PRATILIPI_LIST_API_V4, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getPratilipiListByListNameV6: (
    listName,
    cursor,
    offset,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    if (listName === null) return;
    let params = {
      listName: listName,
      state: "PUBLISHED",
      language: language,
    };

    if (listName.toLowerCase().indexOf("audio") > -1) {
      params = {
        ...params,
        contentType: "AUDIO",
      };
    }
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (timeFilter.fromSec != null) params["fromSec"] = timeFilter.fromSec;
    if (timeFilter.toSec != null) params["toSec"] = timeFilter.toSec;
    httpUtil.get(API_PREFIX + PRATILIPI_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getRecentPratilipiListByListName: (
    listName,
    offset,
    cursor,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    if (listName === null) return;
    const params = { category: listName, language: language };
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (timeFilter.fromSec != null) params["fromSec"] = timeFilter.fromSec;
    if (timeFilter.toSec != null) params["toSec"] = timeFilter.toSec;
    httpUtil.get(API_PREFIX + PRATILIPI_LIST_RECENT_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getRecentPratilipiListByListNameV2: (
    listName,
    offset,
    cursor,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    if (listName === null) return;
    const params = { category: listName, language: language };
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (timeFilter.fromSec != null) params["fromSec"] = timeFilter.fromSec;
    if (timeFilter.toSec != null) params["toSec"] = timeFilter.toSec;
    httpUtil.get(
      API_PREFIX + PRATILIPI_LIST_RECENT_API_V2,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getHighRatedPratilipiListByListName: (
    listName,
    offset,
    cursor,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    if (listName === null) return;
    const params = { category: listName, language: language };
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (timeFilter.fromSec != null) params["fromSec"] = timeFilter.fromSec;
    if (timeFilter.toSec != null) params["toSec"] = timeFilter.toSec;
    httpUtil.get(
      API_PREFIX + PRATILIPI_LIST_HIGH_RATED_API,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getHighRatedPratilipiListByListNameV2: (
    listName,
    offset,
    cursor,
    resultCount,
    language,
    timeFilter,
    aCallBack
  ) => {
    if (listName === null) return;
    const params = { category: listName, language: language };
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    if (timeFilter.fromSec != null) params["fromSec"] = timeFilter.fromSec;
    if (timeFilter.toSec != null) params["toSec"] = timeFilter.toSec;
    httpUtil.get(
      API_PREFIX + PRATILIPI_LIST_HIGH_RATED_API_V2,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPratilipiListBySearchQuery: (
    searchQuery,
    cursor,
    offset,
    resultCount,
    language,
    aCallBack
  ) => {
    if (searchQuery === null) return;
    const params = {
      searchQuery: searchQuery,
      state: "PUBLISHED",
      language: language,
    };
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(API_PREFIX + PRATILIPI_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getPratilipiListByAuthor: (
    authorId,
    state,
    cursor,
    offset,
    resultCount,
    aCallBack
  ) => {
    if (authorId === null) return;
    const params = {
      authorId: authorId,
      state: state != null ? state : "PUBLISHED",
    };
    if (state === "DRAFTED") params["orderByListingDate"] = false;
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(API_PREFIX + PRATILIPI_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getPublishedListByAuthorId: (userId, authorId, state, limit, aCallBack) => {
    if (authorId === null) return;
    const params = {
      authorId: authorId,
      state: state != null ? state : "PUBLISHED",
      limit: limit,
      offset: 0,
    };
    httpUtil.get(
      API_PREFIX + AUTHOR_PROFILE_API + userId,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getMorePublishedListByAuthorId: (userId, nextSegment, aCallBack) => {
    if (nextSegment === null) return;
    const params = nextSegment;
    httpUtil.get(
      API_PREFIX + AUTHOR_PROFILE_API + userId,
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPratilipiListByEventId: (
    eventId,
    cursor,
    offset,
    resultCount,
    aCallBack
  ) => {
    if (eventId === null) return;
    const params = { eventId: eventId, state: "PUBLISHED" };
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(API_PREFIX + PRATILIPI_LIST_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getHomePageSections: (offset, limit, language, bucketId, aCallBack) => {
    const params = { limit, language, bucketId };
    if (offset != null) params["offset"] = offset;
    httpUtil.get(API_PREFIX + INIT_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getHomePageBanners: (language, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_BANNER_LIST_API,
      null,
      { language: language },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getAuthorFollowers: (authorId, cursor, offset, resultCount, aCallBack) => {
    if (authorId === null) return;
    const params = { authorId: authorId };
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(
      API_PREFIX +
        USER_AUTHOR_FOLLOWERS_LIST_API +
        "/" +
        authorId +
        "/followers",
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getUserFollowing: (userId, cursor, offset, resultCount, aCallBack) => {
    if (userId === null) return;
    const params = { userId: userId };
    if (cursor != null) params["cursor"] = cursor;
    if (offset != null) params["offset"] = offset;
    if (resultCount != null) params["resultCount"] = resultCount;
    httpUtil.get(
      API_PREFIX + USER_AUTHOR_FOLLOWING_LIST_API + "/" + userId + "/following",
      null,
      params,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPageContent: (pageName, language, aCallBack) => {
    if (pageName === null) return;
    httpUtil.get(
      API_PREFIX + PAGE_CONTENT_API,
      null,
      { pageName: pageName, language: language },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getHelpSections: (language, aCallBack) => {
    if (language === null) return;
    httpUtil.get(
      API_PREFIX + HELP_SECTIONS_API,
      null,
      { section: "help", language: language },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPratilipiContent: (pratilipiId, chapterNo, oldContent, aCallBack) => {
    if (pratilipiId === null)
      return; /* chapterNo can be null for IMAGE contents */
    httpUtil.get(
      API_PREFIX + PRATILIPI_CONTENT_API,
      null,
      {
        pratilipiId: pratilipiId,
        chapterNo: chapterNo,
        _apiVer: oldContent ? 1 : 3,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPratilipiRecommendation: (
    language,
    contextId,
    context,
    resultCount,
    aCallBack
  ) => {
    if (contextId === null) return;
    httpUtil.get(
      API_PREFIX + RECOMMENDATION_PREFIX + RECOMMENDATION_PRATILIPI_API,
      { "Bucket-Id": getCookie("bucket_id") },
      {
        language: language,
        contextId: contextId,
        context: context,
        resultCount: resultCount,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  /* POST Methods */
  createOrUpdateUser: (
    userId,
    email,
    phone,
    successCallBack,
    errorCallBack
  ) => {
    if (userId === null) return;
    const params = { userId: userId };
    if (email != null) params["email"] = email;
    if (phone != null) params["phone"] = phone;
    httpUtil.post(API_PREFIX + USER_API, null, params, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  createOrUpdateAuthor: (author, successCallBack, errorCallBack) => {
    if (author === null || author.authorId === null) return;
    if (author.dateOfBirth) {
      const dateObj = new Date(author.dateOfBirth);
      const date = dateObj.getDate();
      let month = dateObj.getMonth();
      month++;
      const year = dateObj.getFullYear();
      author.dateOfBirth = date + "-" + month + "-" + year;
    }
    httpUtil.post(API_PREFIX + AUTHOR_API, null, author, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  createReview: (pratilipiId, rating, successCallBack, errorCallBack) => {
    if (pratilipiId === null) return;
    const params = {};
    if (rating != null) params["rating"] = rating;
    const URL =
      API_PREFIX + USER_PRATILIPI_REVIEW_API_V2 + `/${pratilipiId}/reviews`;
    httpUtil.post(URL, null, params, function(response, status) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  updateReview: (
    pratilipiId,
    rating,
    review,
    reviewId,
    successCallBack,
    errorCallBack
  ) => {
    if (pratilipiId === null) return;
    const params = {};
    if (rating != null) params["rating"] = rating;
    if (review != null) params["review"] = review;
    params["state"] = "PUBLISHED";
    const URL =
      API_PREFIX +
      USER_PRATILIPI_REVIEW_API_V2 +
      `/${pratilipiId}/reviews/${reviewId}`;
    httpUtil.patchWithBody(URL, null, params, function(response, status) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  createOrUpdateReview: (
    pratilipiId,
    rating,
    review,
    action,
    successCallBack,
    errorCallBack
  ) => {
    if (pratilipiId === null) return;
    const params = {};
    if (rating != null) params["rating"] = rating;
    if (review != null) {
      params["review"] = review;
      params["reviewState"] = "PUBLISHED";
    }
    if (action === "REVIEW") {
      const URL =
        API_PREFIX + USER_PRATILIPI_REVIEW_API_V2 + `/${pratilipiId}/reviews`;
      httpUtil.post(URL, null, params, function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      });
    }
  },

  createOrUpdateReviewComment: (
    userPratilipiId,
    commentId,
    content,
    successCallBack,
    errorCallBack
  ) => {
    if (userPratilipiId === null && commentId === null) return;
    const params = { state: "ACTIVE" };
    if (userPratilipiId != null) {
      params["parentId"] = userPratilipiId;
      params["parentType"] = "REVIEW";
    }
    if (content != null) params["comment"] = content;
    let URL = API_PREFIX + REVIEW_COMMENT_API + `/${userPratilipiId}/comments`;
    if (commentId != null) {
      URL = URL + `/${commentId}`;
      httpUtil.patchWithBody(URL, null, params, function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      });
    } else {
      httpUtil.post(URL, null, params, function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      });
    }
  },

  followOrUnfollowAuthor: (
    authorId,
    following,
    successCallBack,
    errorCallBack
  ) => {
    if (authorId === null || following === null) return;
    httpUtil.post(
      API_PREFIX + USER_AUTHOR_FOLLOW_POST_API + "/" + authorId,
      null,
      {
        authorId: authorId,
        state: following ? "FOLLOWING" : "UNFOLLOWED",
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  addOrRemoveFromLibrary: (
    pratilipiId,
    addedToLib,
    successCallBack,
    errorCallBack
  ) => {
    if (pratilipiId === null || addedToLib === null) return;
    if (addedToLib) {
      addToLibrary("pratilipis", pratilipiId, successCallBack, errorCallBack);
    } else {
      deleteFromLibrary(
        "pratilipis",
        pratilipiId,
        successCallBack,
        errorCallBack
      );
    }
  },

  addOrRemoveSeriesFromLibrary: (
    seriesId,
    addedToLib,
    successCallBack,
    errorCallBack
  ) => {
    if (seriesId === null || addedToLib === null) return;
    if (addedToLib) {
      addToLibrary("series", seriesId, successCallBack, errorCallBack);
    } else {
      deleteFromLibrary("series", seriesId, successCallBack, errorCallBack);
    }
  },

  // P2P 2.0
  authenticateWithCognito: (
    cognitoRefreshToken,
    successCallBack,
    errorCallBack
  ) => {
    if (cognitoRefreshToken) {
      httpUtil.post(
        API_PREFIX + COGNITO_AUTH_API,
        null,
        { refreshToken: cognitoRefreshToken },
        function(response, status) {
          processPostResponse(response, status, successCallBack, errorCallBack);
        }
      );
    } else {
      httpUtil.post(API_PREFIX + COGNITO_AUTH_API, null, null, function(
        response,
        status
      ) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      });
    }
  },

  getAuthorConciseMetadata: (userIds, aCallback) => {
    httpUtil.get(
      API_PREFIX + AUTHOR_CONCISE_METADATA_API,
      null,
      { userIds },
      function(response, status) {
        processGetResponse(response, status, aCallback);
      }
    );
  },

  getInitialConversation: (
    cognitoAccessToken,
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.postJson(
      APPSYNC_P2P_API,
      { Authorization: cognitoAccessToken },
      {
        operationName: "allConversationConnection",
        query:
          "query allConversationConnection($limit: Int) { allConversationConnection(limit: $limit) { conversations {conversationId, userId, lastMessage { type, value, sendTime}, conversationState, isUnread}, nextToken }}",
        variables: {
          limit: 20,
        },
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  getMoreConversation: (
    cognitoAccessToken,
    nextToken,
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.postJson(
      APPSYNC_P2P_API,
      { Authorization: cognitoAccessToken },
      {
        operationName: "allConversationConnection",
        query:
          "query allConversationConnection($limit: Int, $after: String) { allConversationConnection(limit: $limit, after: $after) { conversations {conversationId, userId, lastMessage { type, value, sendTime}, conversationState, isUnread}, nextToken }}",
        variables: {
          limit: 20,
          after: nextToken,
        },
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  // LIBRARY FUNCTIONS
  // GET
  getUserLibraryList: (offset, limit, aCallBack) => {
    const params = {};
    if (offset != null) params["offset"] = offset;
    if (limit != null) params["limit"] = limit;
    httpUtil.get(API_PREFIX + LIBRARY_LIST_V_1_1_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  // POST
  addToLibrary: addToLibrary,

  // DELETE
  deleteFromLibrary: deleteFromLibrary,

  likeOrDislikeReview: (reviewId, hasLiked, successCallBack, errorCallBack) => {
    if (reviewId === null || hasLiked === null) return;
    httpUtil.post(
      API_PREFIX + VOTE_API,
      null,
      {
        parentId: reviewId,
        parentType: "REVIEW",
        type: hasLiked ? "LIKE" : "NONE",
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  likeOrDislikeComment: (
    commentId,
    hasLiked,
    successCallBack,
    errorCallBack
  ) => {
    if (commentId === null || hasLiked === null) return;
    httpUtil.post(
      API_PREFIX + VOTE_API,
      null,
      {
        parentId: commentId,
        parentType: "COMMENT",
        type: hasLiked ? "LIKE" : "NONE",
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  deleteReview: (pratilipiId, reviewId, successCallBack, errorCallBack) => {
    if (pratilipiId === null || reviewId === null) return;
    const URL =
      API_PREFIX +
      USER_PRATILIPI_REVIEW_API_V2 +
      `/${pratilipiId}/reviews/${reviewId}`;
    const params = { state: "DELETED" };
    httpUtil.patchWithBody(URL, null, params, function(response, status) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  deleteComment: (reviewId, commentId, successCallBack, errorCallBack) => {
    if (reviewId === null && commentId === null) return;
    const params = { state: "DELETED" };
    params["parentId"] = reviewId;
    params["parentType"] = "REVIEW";
    let URL = API_PREFIX + REVIEW_COMMENT_API + `/${reviewId}/comments`;
    URL = URL + `/${commentId}`;
    httpUtil.patchWithBody(URL, null, params, function(response, status) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  createOrUpdatePratilipi: (pratilipi, successCallBack, errorCallBack) => {
    if (pratilipi === null) return;
    if (pratilipi["pratilipiId"] === null) pratilipi["oldContent"] = false;
    httpUtil.post(API_PREFIX + PRATILIPI_API, null, pratilipi, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  loginUser: (email, password, successCallBack, errorCallBack) => {
    if (email === null || password === null) return;
    httpUtil.get(API_PREFIX + ACCESS_TOKEN_API, null, null,
        function (response, status) {
          setCookie("access_token", response.accessToken);
          httpUtil.post(
              API_PREFIX + USER_LOGIN_API,
              {"AccessToken": response.accessToken},
              { email: email, password: password },
              function(response, status) {
                processPostResponse(
                    response,
                    status,
                    successCallBack,
                    errorCallBack
                );
              }
          );
        }, false);
  },

  loginGoogleUser: (
    googleIdToken,
    language,
    successCallBack,
    errorCallBack
  ) => {
    if (googleIdToken === null) return;
    httpUtil.post(
      API_PREFIX + USER_LOGIN_GOOGLE_API,
      null,
      { googleIdToken: googleIdToken, language: language },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  loginFacebookUser: (
    fbUserAccessToken,
    language,
    successCallBack,
    errorCallBack
  ) => {
    if (fbUserAccessToken === null) return;
    httpUtil.post(
      API_PREFIX + USER_LOGIN_FACEBOOK_API,
      null,
      { fbUserAccessToken: fbUserAccessToken, language: language },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  registerUser: (
    name,
    email,
    password,
    language,
    successCallBack,
    errorCallBack
  ) => {
    if (name === null || email === null || password === null) return;
    httpUtil.post(
      API_PREFIX + USER_REGISTER_API,
      null,
      {
        name: name,
        email: email,
        password: password,
        language: language,
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  updateUserPassword: (
    password,
    newPassword,
    successCallBack,
    errorCallBack
  ) => {
    if (password === null || newPassword === null) return;
    httpUtil.post(
      API_PREFIX + USER_PASSWORD_UPDATE_API,
      null,
      { password: password, newPassword: newPassword },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  forgotPassword: (email, successCallBack, errorCallBack) => {
    if (email === null) return;
    httpUtil.post(
      API_PREFIX + USER_EMAIL_API,
      null,
      { email: email, sendPasswordResetMail: true },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  resetUserPassword: (
    email,
    verificationToken,
    newPassword,
    successCallBack,
    errorCallBack
  ) => {
    if (email === null || verificationToken === null || newPassword === null)
      return;
    httpUtil.post(
      API_PREFIX + USER_PASSWORD_UPDATE_API,
      null,
      {
        email: email,
        verificationToken: verificationToken,
        newPassword: newPassword,
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  logoutUser: (successCallBack, errorCallBack) => {
    httpUtil.get(API_PREFIX + USER_LOGOUT_API, null, null, function(
      response,
      status
    ) {
      status === 200
        ? successCallBack(response)
        : errorCallBack(
            response
          ); /* Logout is different from all other cases */
    });
  },

  deleteCookie: (name) => {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },

  updateNotificationState: (
    notificationId,
    state,
    successCallBack,
    errorCallBack
  ) => {
    if (notificationId === null || state === null) return;
    httpUtil.patch(
      API_PREFIX + NOTIFICATION_API + "/" + notificationId,
      null,
      { state: state },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  getTags: (language, aCallBack) => {
    if (language === null) return;
    httpUtil.get(API_PREFIX + TAGS_API, null, { language: language }, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  updatePratilipiTags: (
    pratilipiId,
    type,
    tagIds,
    suggestedTags,
    successCallBack,
    errorCallBack
  ) => {
    if (pratilipiId === null) return;
    httpUtil.post(
      API_PREFIX + PRATILIPI_TAGS_UPDATE_API,
      null,
      {
        pratilipiId: pratilipiId,
        type: type,
        tagIds: JSON.stringify(tagIds),
        suggestedTags: JSON.stringify(suggestedTags),
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  reportContent: (
    name,
    email,
    phone,
    message,
    dataType,
    dataId,
    language,
    successCallBack,
    errorCallBack
  ) => {
    if (name === null || name.trim() === "") return;
    if (
      (email === null || email.trim() === "") &&
      (phone === null || phone.trim() === "")
    )
      return;
    if (message === null || message.trim() === "") return;
    const team = "AEE_" + language;
    const body = {
      name: name,
      team: team,
      message: message,
      language: language,
    };
    if (email != null) body["email"] = email;
    if (phone != null) body["phone"] = phone;
    if (dataType != null && dataId != null) {
      body["data"] = JSON.stringify({
        type: dataType,
        id: dataId,
      });
    }
    httpUtil.post(API_PREFIX + CONTACT_API, null, body, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  getYesterdayTopAuthors: (language, resultCount, aCallBack) => {
    if (language === null) return;
    httpUtil.get(
      API_PREFIX + TOP_AUTHORS_API,
      null,
      { language: language, resultCount: resultCount || 20 },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },
  uploadCoverImage: (formData, authorId, successCallBack, errorCallBack) => {
    if (formData === null) return;
    if (authorId === null) return;

    httpUtil.postMultipart(
      API_PREFIX + "/author/cover?authorId=" + authorId,
      null,
      formData,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  uploadProfileImage: (formData, authorId, successCallBack, errorCallBack) => {
    if (formData === null) return;
    if (authorId === null) return;

    httpUtil.postMultipart(
      API_PREFIX + "/author/image?authorId=" + authorId,
      null,
      formData,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  uploadPratilipiImage: (
    formData,
    pratilipiId,
    successCallBack,
    errorCallBack
  ) => {
    if (formData === null) return;
    if (pratilipiId === null) return;

    httpUtil.postMultipart(
      API_PREFIX + "/pratilipi/cover?pratilipiId=" + pratilipiId,
      null,
      formData,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  uploadEventPratilipiImage: (formData, successCallBack, errorCallBack) => {
    httpUtil.postMultipart(
      API_PREFIX + "/event-participate/images?type=" + "PRATILIPI",
      null,
      formData,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  getTrendingSearchKeywords: function(language, aCallBack) {
    httpUtil.get(
      API_PREFIX + SEARCH_PREFIX + SEARCH_TRENDING_API,
      null,
      { language: language },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getInitialSearchResults: function(searchQuery, language, aCallBack) {
    if (searchQuery === null) return;
    httpUtil.get(
      API_PREFIX + SEARCH_PREFIX + SEARCH_CORE_API,
      null,
      { language: language, text: searchQuery.trim() },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPratilipiSearchResults: function(
    searchQuery,
    cursor,
    resultCount,
    language,
    aCallBack
  ) {
    if (searchQuery === null || cursor === null) return;
    httpUtil.get(
      API_PREFIX + SEARCH_CORE_API,
      null,
      {
        language: language,
        text: searchQuery.trim(),
        pratilipiCursor: cursor,
        pratilipiResultCount: resultCount != null ? resultCount : 20,
        authorResultCount: 0,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  khojV1: {
    getTrendingSearchKeywords: function(language, aCallBack) {
      httpUtil.get(KHOJ_V1_TRENDING, null, { language: language }, function(
        response,
        status
      ) {
        processGetResponse(response, status, aCallBack);
      });
    },

    getInitialSearchResults: function(searchQuery, language, aCallBack) {
      searchQuery &&
        httpUtil.get(
          KHOJ_V1_SEARCHBYQUERY,
          null,
          {
            language: language,
            text: searchQuery.trim(),
            pageAuthor: "limit=20",
          },
          function(response, status) {
            processGetResponse(response, status, aCallBack);
          }
        );
    },

    getPratilipiSearchResults: function(
      searchQuery,
      nextSegment,
      resultCount,
      language,
      aCallBack
    ) {
      if (language === null || searchQuery === null || nextSegment === null)
        return;

      httpUtil.get(
        KHOJ_V1_SEARCHBYQUERY,
        null,
        {
          language: language,
          text: searchQuery.trim(),
          pageContent: nextSegment,
        },
        function(response, status) {
          processGetResponse(response, status, aCallBack);
        }
      );
    },
  },

  getEventPratilipiById: (eventPratilipiId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + PRATILIPI_API,
      null,
      {
        pratilipiId: eventPratilipiId,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getEventPratilipiList: (eventId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX + EVENT_PARTICIPATE_LIST,
      null,
      { eventId },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getDraftedEventPratilipis: (eventId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX + EVENT_PARTICIPATE_LIST,
      null,
      { eventId, state: "DRAFTED" },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  createEventPratilipi: (
    { language, title, titleEn, type, eventId, state },
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX + EVENT_PARTICIPATE_CREATE,
      null,
      {
        language,
        title,
        titleEn,
        eventId,
        type,
        state,
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },
  updateEventPratilipi: (
    eventPratilipiId,
    { language, title, titleEn, type },
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX + EVENT_PARTICIPATE_UPDATE,
      null,
      {
        language,
        title,
        titleEn,
        type,
        eventPratilipiId,
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  updateEventPratilipiDesc: (
    eventPratilipiId,
    { description, state },
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX + EVENT_PARTICIPATE_UPDATE,
      null,
      {
        description,
        eventPratilipiId,
        state,
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  bulkSaveChapters: (
    eventPratilipiId,
    contents,
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX + EVENT_PARTICIPATE_CONTENT,
      null,
      { contents: encodeURIComponent(contents), eventPratilipiId },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },
  getEventPratilipiContent: (eventPratilipiId, chapterNo, aCallBack) => {
    httpUtil.get(
      API_PREFIX + CONTENT_API,
      null,
      {
        pratilipiId: eventPratilipiId,
        chapterNo: chapterNo,
        _apiVer: 3,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getPratilipiIndex: (eventPratilipiId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + CONTENT_API + INDEX_API,
      null,
      {
        pratilipiId: eventPratilipiId,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },
  getAdminEventPratilipis: (query, aCallBack) => {
    httpUtil.get(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX_ADMIN + EVENT_PARTICIPATE_LIST,
      null,
      query,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getAdminEventPratilipi: (eventPratilipiId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX_ADMIN + EVENT_PARTICIPATE_GET,
      null,
      { eventPratilipiId },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  publishAdminEventPratilipi: (id, successCallBack, errorCallBack) => {
    httpUtil.post(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX_ADMIN + EVENT_PARTICIPATE_PUBLISH,
      null,
      { id },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  getAuthorByUserId: (userId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + AUTHOR_NEW_API,
      null,
      {
        userId,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getAuthorDashboardByAuthorId: (authorId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + AUTHOR_DASHBOARD_API,
      null,
      {
        authorId,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getReaderDashboardByUserId: (userId, aCallBack) => {
    httpUtil.get(
      API_PREFIX + READER_DASHBOARD_API,
      null,
      {
        userId,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  updateUserPreference: (type, value, successCallBack, errorCallBack) => {
    httpUtil.post(
      API_PREFIX + USER_PREFERENCE_API,
      null,
      { type, value },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  updateAdminEventPratilipi: (data, successCallBack, errorCallBack) => {
    httpUtil.post(
      API_PREFIX + EVENT_PARTICIPATE_PREFIX_ADMIN + EVENT_PARTICIPATE_UPDATE,
      null,
      data,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  createOrUpdateFCMToken: (
    fcmToken,
    language,
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + WEB_DEVICES_API,
      null,
      { fcmToken: JSON.stringify(fcmToken), language },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  postMarketingNewsletterUnsubscribe: (
    uuid,
    newsletterFrequency,
    newsletterUnsubscribeReason,
    campaignId,
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + MARKETING_API_UNSUBSCRIBE,
      null,
      {
        uuid,
        newsletterFrequency,
        newsletterUnsubscribeReason,
        campaignId,
      },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  postReferralUnsubscribe: (
    invitationId,
    channelAddress,
    reason,
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + REFERRAL_API_UNSUBSCRIBE,
      null,
      { invitationId, channelAddress, reason },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  postMarketingNewsletterPreference: (
    uuid,
    type,
    value,
    successCallBack,
    errorCallBack
  ) => {
    httpUtil.post(
      API_PREFIX + MARKETING_API_PREFERENCE,
      null,
      { uuid, type, value },
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  postReadingPercent: (
    pratilipiId,
    chapterNo,
    percentageScrolled,
    index,
    successCallBack,
    errorCallBack
  ) => {
    const tempIndex = JSON.parse(JSON.stringify(index));

    for (const key in tempIndex) {
      delete tempIndex[key].title;
      delete tempIndex[key].pageUrl;
      delete tempIndex[key].nesting;
    }

    const params = {
      pratilipiId: pratilipiId,
      chapterNo: chapterNo,
      percentageScrolled: percentageScrolled,
      index: JSON.stringify(tempIndex),
      agent: "Web",
    };
    httpUtil.post(API_PREFIX + READ_PERCENTAGE_API, null, params, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  getJokeOfTheDay: (language, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_API_VAPSI,
      null,
      {
        language: language,
        vapsiType: "JOKE",
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getQuoteOfTheDay: (language, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_API_VAPSI,
      null,
      {
        language: language,
        vapsiType: "QUOTE",
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getHoroscopeOfTheDay: (language, aCallBack) => {
    httpUtil.get(
      API_PREFIX + INIT_API_VAPSI,
      null,
      {
        language: language,
        vapsiType: "HOROSCOPE",
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getWriterCornerWidgets: ({ authorId, language }, aCallBack) => {
    httpUtil.get(
      API_PREFIX + WRITER_CORNER_API,
      null,
      {
        language: language,
        authorId: authorId,
      },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  addNewEventEntryFromPratilipi: (
    eventId,
    userId,
    authorId,
    pratilipiId,
    successCallBack,
    errorCallBack
  ) => {
    const params = {
      eventId: eventId,
      userId: userId,
      authorId: authorId,
      pratilipiId: pratilipiId,
    };

    httpUtil.post(API_PREFIX + EVENT_ENTRY_API, null, params, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  addNewChapterToPratilipi: (
    pratilipiId,
    chapterNo,
    successCallBack,
    errorCallBack
  ) => {
    const params = {
      pratilipiId: pratilipiId,
      chapterNo: chapterNo,
    };

    httpUtil.post(
      API_PREFIX + CONTENT_API + CHAPTER_ADD,
      null,
      params,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  deleteChapterToPratilipi: (
    pratilipiId,
    chapterNo,
    successCallBack,
    errorCallBack
  ) => {
    const params = {
      pratilipiId: pratilipiId,
      chapterNo: chapterNo,
    };

    httpUtil.post(
      API_PREFIX + CONTENT_API + CHAPTER_DELETE,
      null,
      params,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },

  saveContentOfEventEntry: (params, successCallBack, errorCallBack) => {
    params._apiVer = 4;

    httpUtil.post(API_PREFIX + CONTENT_API, null, params, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  submitEntryToEvent: (eventId, eventEntryId, aCallBack) => {
    httpUtil.patch(
      API_PREFIX + EVENT_ENTRY_API + EVENT_ENTRY_SUBMIT,
      null,
      { eventId: eventId, eventEntryId: eventEntryId },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  deleteEntryToEvent: (eventId, eventEntryId, aCallBack) => {
    const paramString = "?eventId=" + eventId + "&eventEntryId=" + eventEntryId;
    httpUtil.delete(
      API_PREFIX + EVENT_ENTRY_API + paramString,
      null,
      null,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  cancelParticipationToEvent: (eventId, eventEntryId, aCallBack) => {
    httpUtil.patch(
      API_PREFIX + EVENT_ENTRY_API + EVENT_ENTRY_CANCEL_PARTICIPATION,
      null,
      { eventId: eventId, eventEntryId: eventEntryId },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getAuthorLeaderboard: (language, timeRange, offset, aCallBack) => {
    const query = {};
    if (!language) {
      return;
    }

    query["language"] = language;
    if (timeRange) {
      query["period"] = timeRange;
    }

    if (offset) {
      query["offset"] = offset;
    }
    httpUtil.get(API_PREFIX + AUTHOR_LEADERBOARD_API, null, query, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getSeriesBySlug: (slug, aCallBack) => {
    if (!slug) return;
    httpUtil.get(API_PREFIX + SERIES_API, null, { slug }, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getSeriesChaptersById: (seriesId, offset, limit, aCallBack) => {
    if (seriesId === null || seriesId === undefined) return;
    httpUtil.get(
      API_PREFIX + SERIES_CHAPTERS_API,
      null,
      { id: seriesId, offset, limit },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getCategoriesWithoutFilter: (aCallBack) => {
    httpUtil.get(
      API_PREFIX + CATEGORIES_WITHOUT_FILER_API,
      null,
      null,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getListSuggestionsAll: (language, aCallBack) => {
    httpUtil.get(
      API_PREFIX + LIST_SUGGESTIONS_API,
      null,
      { language: language },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getListSuggestionsForListPage: (language, listName, aCallBack) => {
    if (listName === null) return;
    const params = { language: language, list: listName, limit: 20 };
    httpUtil.get(API_PREFIX + LIST_SUGGESTIONS_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getListSuggestionsForCategory: (language, categoryName, aCallBack) => {
    if (categoryName === null) return;
    const params = {
      language: language,
      category: categoryName,
      limit: 20,
    };
    httpUtil.get(API_PREFIX + LIST_SUGGESTIONS_API, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },

  getSettingsUserPreference: (aCallBack) => {
    httpUtil.get(API_PREFIX + USER_PREFERENCE_API, null, null, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },
  updateSettingsUserPreference: (
    type,
    value,
    successCallBack,
    errorCallBack
  ) => {
    const params = { type: type, value: value };
    httpUtil.post(API_PREFIX + USER_PREFERENCE_API, null, params, function(
      response,
      status
    ) {
      processPostResponse(response, status, successCallBack, errorCallBack);
    });
  },

  getTopAuthors: (language, limit, period, aCallBack) => {
    if (language === null) return;
    httpUtil.get(
      API_PREFIX + TOP_AUTHORS_API_V1,
      null,
      { language: language, period: period || 30, limit: limit || 10 },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getWordMeaning: (language, word, aCallBack) => {
    if (language === null) return;
    httpUtil.get(
      API_PREFIX + WORD_MEANING,
      null,
      { language: language, word: word },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },
  getWordSynonyms: (language, word, aCallBack) => {
    if (language === null) return;
    httpUtil.get(
      API_PREFIX + WORD_SYNONYMS,
      null,
      { language: language, word: word },
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  getSeriesListFromAuthor: (userId, authorId, offset, aCallBack) => {
    if (userId === null || authorId === null) return;
    const param = {
      authorId: authorId,
      state: "PUBLISHED",
      limit: parseInt(20),
    };

    if (offset != null) param["offset"] = offset;

    httpUtil.get(
      API_PREFIX + SERIES_LIST_API_AUTHOR + "/" + userId,
      null,
      param,
      function(response, status) {
        processGetResponse(response, status, aCallBack);
      }
    );
  },

  addPratilipiToSeries: (pratilipiId, seriesId, aCallBack) => {
    if (pratilipiId === null || seriesId === null) return;
    let data = [pratilipiId];
    const param = { pratilipiId: data };
    httpUtil.postJsonWithAccessToken(
      API_PREFIX + PRATILIPI_ADD_TO_SERIES + "/" + seriesId + "/pratilipis",
      null,
      param,
      function(response, status) {
        processPostResponse(response, status, aCallBack);
      }
    );
  },
  uploadComicImage: (pratilipiId, image, aCallBack) => {
    if (pratilipiId === null || image === null) return;

    let form = new FormData();
    form.append("data", image);
    httpUtil.postMultipart(
      API_PREFIX +
        PRATILIPI_IMAGE_UPLOAD +
        "?pratilipiId=" +
        pratilipiId.toString(),
      null,
      form,
      function(response, status) {
        processPostResponse(response, status, aCallBack);
      }
    );
  },
  addImagesToComicChapeter: (pratilipiId, chapterId, content, aCallBack) => {
    if (pratilipiId === null || chapterId === null || content == null) return;

    let form = [];
    form.push("pratilipiId" + "=" + pratilipiId);
    form.push("chapterId" + "=" + chapterId);
    form.push("content" + "=" + content);
    let data = {
      pratilipiId: pratilipiId,
      chapterId: chapterId,
      content: content,
    };
    httpUtil.postJsonWithAccessToken(
      API_PREFIX + PRATILIPI_CONTENT_API,
      null,
      data,
      function(response, status) {
        processPostResponse(response, status, aCallBack);
      }
    );
  },

  createSeries: (data, successCallBack, errorCallBack) => {
    if (data === null) return;

    httpUtil.postJsonWithAccessToken(
      API_PREFIX + PRATILIPI_ADD_TO_SERIES,
      null,
      data,
      function(response, status) {
        processPostResponse(response, status, successCallBack, errorCallBack);
      }
    );
  },
  getAuthorCountByAuthorId: (authorID, aCallBack) => {
    const params = {
      pratilipiState: "PUBLISHED,DRAFTED,DELETED",
      seriesPartState: "PUBLISHED,DRAFTED",
      seriesState: "INPROGRESS,COMPLETED",
      authorId: authorID,
    };
    if (authorID === null) return;
    httpUtil.get(API_PREFIX + AUTHOR_COUNT_API_V1, null, params, function(
      response,
      status
    ) {
      processGetResponse(response, status, aCallBack);
    });
  },
};
