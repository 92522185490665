import client, { comicApiGateway } from "./_client";
import * as _ from "lodash";
const http = comicApiGateway();
const endpoint = "";

export async function getSeriesById(seriesId) {
  const _endpoint = `/series/v1.2`;
  const param = {
    id: seriesId,
  };
  const { data } = await http.get(_endpoint, { param });
  return data;
}
export async function fetchSeriesDetailsBySlug(slug) {
  const _endpoint = `/series/v1.2`;
  const param = {
    slug: slug,
  };
  const { data } = await http.get(_endpoint, { param });
  return data;
}

export async function getSeriesParts(
  seriesId,
  state = "PUBLISHED",
  limit = 50
) {
  // pratilipis/v2.0?seriesId=78992&state=PUBLISHED&limit=5&language=HINDI
  const _endpoint = `${endpoint}/pratilipis/v2.0`;
  const params = { seriesId, state, limit };
  const { data } = await http.get(_endpoint, { params });
  return data;
}

/**
 * Find release dates by pratilipi ids
 *
 * @param {Array<number>} pratilipiIdList
 */
export async function getRelease(pratilipiIdList) {
  const _endpoint = `${endpoint}/fevicol/releases`;
  const params = {
    pratilipiIds: pratilipiIdList.join(","),
    limit: pratilipiIdList.length,
  };
  const { data } = await http.get(_endpoint, { params });
  return data;
}

export async function getReleaseByDate(states, start, end) {
  const _endpoint = `${endpoint}/fevicol/releases`;
  const params = {
    state: states.join(","),
    startDate: start,
    endDate: end,
    limit: 1000,
  };
  const { data } = await http.get(_endpoint, { params });
  return data;
}

/**
 * Set release dates by pratilipi id
 *
 * @param {number} pratilipiId
 * @param {Date} releaseAt
 */
export async function setRelease(pratilipiId, releaseAt) {
  const _endpoint = `${endpoint}/fevicol/releases`;
  const params = { pratilipiId, releaseAt };
  const { data } = await http.post(_endpoint, params);
  return data;
}

/**
 * Cancel a release
 *
 * @param {Object} release
 */
export async function cancelRelease({ id }) {
  const _endpoint = `${endpoint}/fevicol/releases/${id}`;
  const { data } = await http.delete(_endpoint);
  return data;
}

/**
 * Get list of available genre names for the language
 *
 * @param language
 * @returns {Promise<void>}
 */
export async function getGenres(language) {
  const _endpoint = `${endpoint}/comics/init/v4.2/comic-list/genres?language=${language}`;
  const { data } = await http.get(_endpoint);
  return data;
}

export async function getMetaSeriesById(seriesId) {
  const _endpoint = `comic-meta/v1.0/series`;
  const params = { id: seriesId }
  const { data } = await http.get(_endpoint, { params });
  return _.first(data.data)
}

export async function getMetaPratilipis(
  seriesId,
  state = "PUBLISHED",
  limit = 10
) {
  const endpoint = "/comic-meta/v1.0/series/pratilipis";
  const params = {id: seriesId, state, limit};
  const {data} = await http.get(endpoint, {params});

  return data.data;
}

export async function createPratilipi(body) {
  const _endpoint = "/comic-meta/v1.0/pratilipi";
  const headers = { "client-type": "WEB" };
  const { data } = await http.post(_endpoint, body, { headers });
  return data.data
}

export async function patchPratilipi(pratilipiId, body) {
  const _endpoint = `/comic-meta/v1.0/pratilipi/${pratilipiId}`;
  const headers = { "client-type": "WEB" };
  const { data } = await http.patch(_endpoint, body, { headers });
  return data.data
}

export async function removePratilipi(pratilipiId) {
  const _endpoint = `/comic-meta/v1.0/pratilipi/${pratilipiId}`;
  const headers = { "client-type": "WEB" };
  const { data } = await http.delete(_endpoint, { headers });
  return data.data
}

export async function getNotices(query) {
  const _endpoint = "/fevicol/notice";
  const { data } = await http.get(_endpoint);
  return data
}
