<template>
  <div>
    <nav class="navbar navbar-expand navbar-light bg-light">
      <div class="container px-2">
        <span class="navbar-brand" @click="triggerHomeEvent">
          <img src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/comics-logo-copy-04.png" class="logo" />
          <!-- <small>pratilipi</small> -->
        </span>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="navbar-nav" :class="language !== 'ur' ? 'mr-auto' : ''">
            <span
              v-text="title"
              class="d-none d-md-block mx-1 font-weight-bold text-truncate"
              style="max-width: 600px"
            ></span>
          </div>

          <div class="navbar-text float-right">
            <button
              v-for="button in buttons"
              :key="button.id"
              @click="$emit('click:menu', button.id)"
              :class="button.class"
              class="mx-1 btn btn-sm"
            >
              <span class="mx-1 text-uppercase font-weight-bold" v-text="button.text"></span>
            </button>
          </div>
          <div
            class="dropdown"
            :class="language === 'ur' ? 'dropright' : 'dropleft'"
            v-if="dropdown && dropdown.buttons.length"
          >
            <button
              class="btn btn-sm border-0 shadow-none"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="pratilipi-icons">&#xe811;</i>
            </button>
            <div class="dropdown-menu border-0 shadow" aria-labelledby="dropdownMenuButton">
              <button
                v-for="button in dropdown.buttons"
                :key="button.id"
                @click="$emit('click:menu', button.id)"
                v-text="button.text"
                :class="button.class"
                class="dropdown-item py-2 pr-3 text-muted border-0"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: ["title", "dropdown", "buttons", "language"],

  methods: {
    triggerHomeEvent() {
      this.$router.push("/me/author");
    },
  }
};
</script>

<style type="text/css">
.brand-text {
  font-size: 14px;
}

.navbar-brand > .logo {
  margin-left: 10px;
  max-height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
}
.mobile-navbar-title {
  max-width: 60px;
  max-height: 24px;
  white-space: nowrap;
  overflow: hidden;
}
</style>
