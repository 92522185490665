import client, { comicApiGateway } from "./_client";
import * as _ from "lodash";
let http = client("Series Service v2");
const endpoint = "series/v2.0";

const metaEndpoint = "comic-meta/v1.0/series";
const metaPratilipiEndpoint = "comic-meta/v1.0/pratilipi";

/**
 * Create a new series
 *
 * @param {Object} partial
 */
export async function create(partial) {
    const comicHttp = comicApiGateway();
    const { data } = await comicHttp.post(metaEndpoint, partial);
    return data.data;
}

/**
 * Create a new pratilipi
 *
 * @param {Object} partial
 */
 export async function createPratilipi(partial) {
    const comicHttp = comicApiGateway();
    const { data } = await comicHttp.post(metaPratilipiEndpoint, partial);
    return data.data;
}

/**
 * Fetch author's series list
 *
 * @param {Number} authorId
 * @param {String} state
 */
export async function getMetaSeriesById(seriesId) {
    const comicHttp = comicApiGateway();
    const _endpoint = `${metaEndpoint}?id=${seriesId}`;
    const { data } = await comicHttp.get(_endpoint);
    return _.first(data.data);
}

/**
 * Fetch author's series list
 *
 * @param {Number} authorId
 * @param {String} state
 */
export async function getSeriesById(seriesId) {
    const _endpoint = `${endpoint}/id/${seriesId}`;
    const { data } = await http.get(_endpoint);
    return data;
}

/**
 * Update series
 *
 * @param {Number} authorId
 * @param {Object} changes
 */
export async function updateSeriesByIdMeta(seriesId, changes) {
    const comicHttp = comicApiGateway();
    const _endpoint = `${metaEndpoint}/${seriesId}`;
    const { data } = await comicHttp.patch(_endpoint, changes);
    return data.data;
}

/**
 * Update series
 *
 * @param {Number} authorId
 * @param {Object} changes
 */
export async function updateSeriesById(seriesId, changes) {
    const comicHttp = comicApiGateway();
    const _endpoint = `${metaEndpoint}/${seriesId}`;
    const { data } = await comicHttp.patch(_endpoint, changes);
    return data.data;
}

/**
 * Fetch author's series list
 *
 * @param {Number} authorId
 * @param {String} state
 */
export async function getDataByAuthorId(
    authorId,
    state,
    options = { limit: 10 }
) {
    const comicHttp = comicApiGateway();
    const _endpoint = '/comics/init/v1.0/me-comics';
    const params = { state, authorId,...options };
    const { data } = await comicHttp.get(_endpoint, { params });
    return data;
}

/**
 * insert pratilipi in series
 *
 */
export async function insertPratilipiToSeries(seriesId, pratilipiId) {
    const _endpoint = `${endpoint}/${seriesId}/pratilipis`;
    const { data } = await http.post(_endpoint, { pratilipiId: [pratilipiId] });
    return data;
}

/**
 * insert pratilipi in series
 *
 */
export async function addPratilipi(seriesId, pratilipiId) {
    const _endpoint = `${endpoint}/${seriesId}/pratilipis`;
    const { data } = await http.post(_endpoint, { pratilipiId: [pratilipiId] });
    return data;
}

/**
 * delete pratilipi in series
 *
 */
export async function removePratilipiFromSeries(seriesId, pratilipiId) {
    const _endpoint = `${endpoint}/${seriesId}/pratilipis/${pratilipiId}`;
    const { data } = await http.delete(_endpoint);
    return data;
}

export async function deleteSeries(seriesId) {
    const _endpoint = `${endpoint}/${seriesId}`;
    const { data } = await http.delete(_endpoint);
    return data;
}
