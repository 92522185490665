/* Http{Util */
import * as _ from "lodash";
const httpUtil = {
  get: (aUrl, headers, params, aCallback, setHeader = true) => {
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    if (params === null) {
      anHttpRequest.open("GET", aUrl, true);
    } else {
      anHttpRequest.open(
        "GET",
        aUrl + (aUrl.indexOf("?") > -1 ? "&" : "?") + formatParams(params),
        true
      );
    }
    anHttpRequest.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );

    /**
        Need to look for an alternative
        **/

    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
        && setHeader
    ) {
      headers = headers || {};
      headers["AccessToken"] =
        headers["AccessToken"] || getCookie("access_token");
      // headers["AccessToken"] = "672222fb-d1bd-41e6-890b-01167bb9d94f";
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(null);
  },

  post: (aUrl, headers, params, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    anHttpRequest.open("POST", aUrl, true);
    anHttpRequest.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );

    /**
        Need to look for an alternative
        **/

    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
      headers["AccessToken"] =
        headers["AccessToken"] || getCookie("access_token");
    }


    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(formatParams(params));
  },

  postWithoutForamt: (aUrl, headers, params, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState == 4 && aCallback != null) {
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    anHttpRequest.open("POST", aUrl, true);
    anHttpRequest.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );

    /**
        Need to look for an alternative
        **/
    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
      headers["AccessToken"] =
        headers["AccessToken"] || getCookie("access_token");
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(params);
  },

  postJson: (aUrl, headers, params, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };

    anHttpRequest.open("POST", aUrl, true);
    anHttpRequest.setRequestHeader("Content-type", "application/json");

    /**
        Need to look for an alternative
        **/
    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(JSON.stringify(params));
  },

  postJsonWithAccessToken: (aUrl, headers, params, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
      headers["AccessToken"] =
        headers["AccessToken"] || getCookie("access_token");
    }
    anHttpRequest.open("POST", aUrl, true);
    anHttpRequest.setRequestHeader("Content-type", "application/json");

    /**
        Need to look for an alternative
        **/
    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(JSON.stringify(params));
  },

  postMultipart: (aUrl, headers, formData, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    anHttpRequest.open("POST", aUrl);

    /**
        Need to look for an alternative
        **/
    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
      headers["AccessToken"] =
        headers["AccessToken"] || getCookie("access_token");
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(formData);
  },

  patch: (aUrl, headers, params, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    anHttpRequest.open(
      "PATCH",
      aUrl + (aUrl.indexOf("?") > -1 ? "&" : "?") + formatParams(params),
      true
    );
    anHttpRequest.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );

    /**
         Need to look for an alternative
         **/

    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
      headers["AccessToken"] =
        headers["AccessToken"] || getCookie("access_token");
      // headers["AccessToken"] = "672222fb-d1bd-41e6-890b-01167bb9d94f";
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(null);
  },

  patchWithBody: (aUrl, headers, body, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    anHttpRequest.open("PATCH", aUrl, true);
    anHttpRequest.setRequestHeader(
      "content-type",
      "application/x-www-form-urlencoded"
    );

    /**
         Need to look for an alternative
         **/

    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
      headers["accesstoken"] =
        headers["AccessToken"] || getCookie("access_token");
      // headers["AccessToken"] = "672222fb-d1bd-41e6-890b-01167bb9d94f";
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(formatParams(body));
  },

  delete: (aUrl, headers, params, aCallback) => {
    if ("onLine" in navigator) {
      if (!navigator["onLine"]) {
        aCallback({ message: "${ _strings.could_not_connect_server }" }, 0);
        return;
      }
    }
    const anHttpRequest = new XMLHttpRequest();
    anHttpRequest.onreadystatechange = function() {
      if (anHttpRequest.readyState === 4 && aCallback !== null) {
        triggerErrorEvent(
          aUrl,
          anHttpRequest.responseText,
          anHttpRequest.status
        );
        aCallback(
          processResponseText(anHttpRequest.responseText),
          anHttpRequest.status
        );
      }
    };
    anHttpRequest.open("DELETE", aUrl, true);
    anHttpRequest.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );

    /*
        Need to look for an alternative
        */
    if (
      (window.location.origin.indexOf(".pratilipi.com") === -1 &&
        window.location.origin.indexOf(".ptlp.co")) === -1
    ) {
      headers = headers || {};
      headers["AccessToken"] =
        headers["AccessToken"] || getCookie("access_token");
    }

    if (headers != null) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          anHttpRequest.setRequestHeader(key, headers[key]);
        }
      }
    }
    anHttpRequest.send(formatParams(params));
  },
};

function processResponseText(repsonseText) {
  let res = {};
  try {
    res = JSON.parse(repsonseText);
  } catch (err) {
    res["message"] = "__('server_error_message')";
  }
  return res;
}

function formatParams(params) {
  if (params == null) return "";
  if (typeof params === "string") return params;
  return Object.keys(params)
    .map(function(key) {
      return key + "=" + params[key];
    })
    .join("&");
}

function getCookie(cname) {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return null;
}

function setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";"
}

function triggerErrorEvent(aUrl, responseText, status) {
  if (status >= 400) {
    const responseErrorObject = {
      response: responseText,
      pageUrl: window.location.href,
      status: status,
      apiUrl: aUrl,
    };
  }
}

export { httpUtil, formatParams, getCookie, setCookie };
