import * as axios from "axios";
import Interceptors from "./_interceptors";
import Utility from "./_utility";

const instance = axios.create({ baseURL: Utility.decideEndpoint() });

/**
 * Attach interceptors for request and response
 */
Interceptors.attach(instance);

/**
 * This could have been a constant export but
 * doing this allows for extension in the future.
 */
export default () => instance;

export const comicApiGateway = () => {
  const i = axios.create({ baseURL: Utility.decideComicsEndpoint() });
  // const i = axios.create({ baseURL: "http://localhost:3333" });
  Interceptors.attach(i);
  return i;
};
