<template>
    <div class="writer-container">
        <link
                rel="stylesheet"
                href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                crossorigin="anonymous"
        />
        <Header
                :title="headerTitle"
                :buttons="headerButtons"
                @click:menu="onHeaderMenuClick"
                class="shadow"
        />
        <div class="container pt-5 pb-5">
            <div class="row" v-if="comics == null">Loading your comics...</div>
            <div class="centered" v-else-if="comics.length == 0">
                <img src="https://comicassets.pratilipi.com/init/comic/static/empty-box-open.png"  alt="logo"/>
                <label style="font-weight: bold; font-size: 17px; margin-top: 25px; margin-left: 75px" class="row">You have uploaded zero comics</label>
                <a @click="createComic()" style="margin-top: 25px" class="myButton col-12">START UPLOADING</a>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col">
                        <pagination
                                class="float-right"
                                :total="seriesListResponse.total"
                                :next="seriesListResponse.nextSegment"
                                :prev="seriesListResponse.prevSegment"
                                :pageSize="pageSize"
                                @click:next="init"
                                @click:prev="init"
                        />
                    </div>
                </div>
                <div class="row d-flex flex-row justify-content-stretch">
                    <div v-for="comic of comics" :key="comic.id" class="col-4 mb-3">
                        <ComicCard :comic="comic" @click="showComic(comic)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import * as _ from "lodash";
    import { mapActions, mapGetters } from "vuex";
    import { apiSeriesV2 } from "../http/DataAccessor.js";
    import dataAccessor from "../http/DataAccessor.js";
    import Pagination from "../components/Pagination.vue";
    import ComicCard from "../components/ComicCard.vue";
    import Header from "../components/Header.vue";
    import mixins from "@/mixins";
    import {getMetaSeriesById, getSeriesById} from "@/http/api/series_v2";
    export default {
        components: {
            Header,
            Pagination,
            ComicCard
        },
        created() {
            if (!this.getUserDetails.authorId) {
                this.$router.replace({ name: "LoginPage" });
                return;
            }

            this.user = this.getUserDetails;
            this.init();
        },
        watch: {},
        mixins: [mixins],
        data: () => ({ seriesListResponse: null, pageSize: 10, user: null, authorId: 0 }),
        computed: {
            comics() {
                return _.get(this.seriesListResponse, "data");
            },
            headerTitle: () => "Author Dashboard",
            headerButtons: () =>
                [
                    {
                        id: "ADD",
                        text: "Add Series",
                        class: "d-none d-md-inline-block"
                    },
                    {
                        id: "ADD-MC",
                        text: "Add Motion Comic",
                        class: "d-none d-md-inline-block"
                    },
                    {
                        id: "LOGOUT",
                        text: "Logout",
                        class: "d-none d-md-inline-block"
                    }
                ].filter(b => ("show" in b ? b.show : true)),
            ...mapGetters(["getUserDetails"])
        },
        methods: {
            init(options = { limit: this.pageSize }) {
                this.authorId = this.getUserDetails.authorId;
                const state = "INPROGRESS,COMPLETED,DRAFTED";
                apiSeriesV2
                    .getDataByAuthorId(this.authorId, state, options)
                    .then(data => {
                        this.seriesListResponse = data
                    });
            },
            showComic(comic) {
                const params = { comicId: comic.id };
                apiSeriesV2.getMetaSeriesById(comic.id).then(data => {
                  const route = data.contentType === "VIDEO" ? "MotionComicUploader" : "ComicsDetail"
                  this.$router.push({ name: route, params });
                });
            },
            async createComic(type = "IMAGE") {
                const title = type === "VIDEO" ? "Ek Naya Motion Comic" : "Ek Naya Comic";
                const data = {
                    title: title,
                    language: this.$language.value,
                    contentType: type,
                    type: "STORY",
                    state: "DRAFTED",
                    authorId: this.authorId,
                };

                const comic = await apiSeriesV2.create(data);
                this.showComic({ id: comic.seriesId });
            },
            async onHeaderMenuClick(id) {
                if(id == "ADD") {
                  this.triggerAnanlyticsEvent("CLICK_ADD_SERIES")
                  await this.createComic()
                } else if (id == "ADD-MC") {
                  await this.createComic("VIDEO")
                  this.triggerAnanlyticsEvent("CLICK_ADD_MC")
                } else if (id == "LOGOUT") {
                    await dataAccessor.logoutUser();
                    dataAccessor.deleteCookie('access_token');
                    window.location.reload()
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .writer-container {
        background-color: #f9f9f9;
    }

    .editor-wrapper-container {
        max-width: 900px;
    }
    .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .myButton {
        background-color:#30E340;
        border-radius:6px;
        display:inline-block;
        cursor:pointer;
        color:#ffffff;
        font-family:Arial;
        font-size:17px;
        font-weight:bold;
        padding:16px 31px;
        text-decoration:none;
        text-shadow:0px 1px 0px #000000;
    }
</style>
